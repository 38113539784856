import React, {useRef, useEffect} from "react";
import Navigation from "../Navigation/Navigation";
import styles from "./Header.module.scss";
// import LanguageSelector from "../LanguageSelector/LanguageSelector";
import UserGameData from "../UserGameData";
import { useUserInfo } from "../../contextFactory";
import {convertNews} from "../../utils/newsUtils";

const Header = () => {
  // @Todo: return language selector in this file when it is needed again
  const { authStatus } = useUserInfo();
  const isLoggedIn = authStatus?.isLoggedIn;
  const newsUrl = process.env.REACT_APP_NEWS_URL;
  const newsCached = useRef({
    cached: false
  });

  useEffect(() => {
    if (newsCached.current.cached) {
      return;
    }
    console.log("News cache");
    fetch(newsUrl)
      .then((response) => response.json())
      .then((json) => convertNews(json))
      .then((convertedNews) => {
        window.localStorage.setItem("newsCached", JSON.stringify(convertedNews));
      })
      .catch((error) => {
          console.log(error);
      });
    newsCached.current = {
      cached: true
    };
  });

  return (
    <>
      <header className={styles.header}>
        <Navigation />
      </header>
      {isLoggedIn && <UserGameData mobileElem={true} />}
    </>
  );
};

export default Header;
