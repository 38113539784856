/**
 * TODO: This file is temporarily moved inside the Game Website project itself due to hot-loading and typing issues.
 */

import { notifyPurchasePopupClosed, notifyAccountChanged } from './UnityLoader'
import { singularSdk } from "singular-sdk";

// This file is part of Metaplay SDK which is released under the Metaplay SDK License.

/**
 * Information about user's account.
 */
export type AccountInfo = {
  /**
   * User's account id, or null if user not logged in.
   */
  accountId: string | null,
}

/**
 * Configuration options for MetaplayApp class.
 * This is the browser-side implementation of the C# class MetaplayWebAppArgs.
 */
export type Options = {
  projectName: string
  accountApiUrl: string
  gameServerUrl: string
  environment: string
}

/**
 * Request object for `CompanyAccountWebGLApiBridge.GetWebTokenAsync`.
 */
type GetWebTokenAsyncRequest = {
  baseUrl: string
  projectName: string
  environment: string
  deviceId: string
}

/**
 * Response object for `CompanyAccountWebGLApiBridge.GetWebTokenAsync`.
 */
type GetWebTokenAsyncResponse = {
  status: number,
  content: string
}

/**
 * This is what the Metaplay App modules looks like on the Unity Instance.
 */
type MetaplayApiBridge = {
  CompanyAccountWebGLApiBridge: {
    GetWebTokenAsync: (request: GetWebTokenAsyncRequest) => Promise<GetWebTokenAsyncResponse>
    OpenLoginPage: (loginServiceUrl: string) => void
    OpenManagementPage: (managementUrl: string) => void
    AccountChanged: (dummy: string) => void
  },
  WebGLBridge: {
    OpenPurchasePopup: (url: string) => void
    OnXsollaPurchase: (usdRevenue: number) => void
  },
  MetaplayApp: any
  IsInitialized: any
}

/**
 * Event handles for all possible types of event that MetaplayApp can pass to clients.
 */
export type EventHandler = {
  onAccountChanged?: (accountInfo: AccountInfo) => void
}

/**
 * Unity Instance.
 * See: https://docs.unity3d.com/Manual/webgl-templates.html
 */
export type UnityInstance = {
  /**
   * The SetFullscreen method toggles full screen mode.
   * @param fullscreen 1 to enable fullscreen mode, 0 to disable.
   */
  SetFullscreen: (fullscreen: number) => void
  /**
   * The SendMessage method sends messages to the GameObjects.
   * @param objectName Name of the object in your scene.
   * @param methodName Name of a method in the script, currently attached to the object.
   * @param value Data to send to the method.
   */
  SendMessage: (objectName: string, methodName: string, value?: string | number) => void
  /**
   * Use the Quit() method to quit the runtime and clean up the memory used by the Unity instance.
   * @returns A promise that completes after the build runtime has quit.
   */
  Quit: () => Promise<() => void>,
  /**
   * Access to internal modules.
   */
  Module: { [name: string]: any }
}

/**
 * MetaplayApp.
 */
export class MetaplayApp {
  // Options, as passed to `initialize`.
  private options?: Options

  // Current user account information.
  private accountInfo: AccountInfo

  // Optional bridge to a Unity instance's MetaplayApi. See `initApiBridge`.
  private metaplayApiBridge?: MetaplayApiBridge

  // List of registered event handles.
  private eventHandlers: { [id: number]: EventHandler }

  // Used internally to generate subscriber Ids.
  private nextEventHandlerId: number
  private loginPopup: any
  private purchasePopup: any;

  /**
   * Constructor.
   */
  constructor () {
    // initialization.
    this.loginPopup = null
    this.accountInfo = { accountId: null }
    this.eventHandlers = {}
    this.nextEventHandlerId = 0

    // Hook listener for messages from login popup.
    const self = this
    window.addEventListener('message', async function (event) {
      if (event.data === 'Metaplay:LoginCompleted') {
        self.loginPopup.close()
        self.loginPopup = null
        await self.refreshAccount()
      }
    })
  }

  /**
   * Call to initialize the MetaplayApp.
   * @param options Configuration options.
   */
  async initialize (options: Options) {
    // Remember configuration options.
    this.options = options

    // Fetch the initial account.
    await this.refreshAccount()
  }

  /**
   * Client subscribes to receives events. Keep hold of the subscription Id and remember to `unsubscribeFromEvents` if
   * the handler goes out of scope.
   */
  subscribeToEvents (eventHandler: EventHandler): number {
    // Remember the handler.
    const eventHandlerId = this.nextEventHandlerId++
    this.eventHandlers[eventHandlerId] = eventHandler

    // Catch up on current state events.
    if (eventHandler.onAccountChanged) {
      eventHandler.onAccountChanged(this.accountInfo)
    }

    return eventHandlerId
  }

  /**
   * Unsubscribe from receiving events.
   * @param eventHandlerId Id previously returned from `subscribeToEvents`.
   */
  unsubscribeFromEvents (eventHandlerId: number) {
    if (!this.eventHandlers[eventHandlerId]) {
      throw new Error('Bad subscription Id.')
    }
    delete this.eventHandlers[eventHandlerId]
  }

  /**
   * Helper function ot send `onAccountChanged` events.
   * @param accountInfo New account information.
   */
  private doOnAccountChanged (accountInfo: AccountInfo) {
    Object.keys(this.eventHandlers).forEach((id: any) => {
      if (this.eventHandlers[id].onAccountChanged) {
        this.eventHandlers[id].onAccountChanged!(this.accountInfo)
      }
    })
  }

  /**
   * Helper function to see whether a user is logged in or not.
   * @returns True if user logged in, otherwise false.
   */
  isLoggedIn () {
    return !!this.accountInfo?.accountId
  }

  /**
   * Fetches the web access token from the account server. This token is needed when making requests to the game server.
   * @returns Promise of the token Id string.
   */
  private async fetchGameServerAccessToken (): Promise<string> {
    // Rely on browser cache to avoid unnecessary fetches.
    const tokenResponse = await fetch(`${this.options?.accountApiUrl}/tokenForGameWebAccess?project=${this.options?.projectName}&env=${this.options?.environment}`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      cache: 'default'
    })

    // Check the response.
    if (tokenResponse.status === 200) {
      const token = await tokenResponse.text()
      return token
    } else {
      throw Error(`Unable to get an access token from AccountApi to access the game server, server responded with code ${tokenResponse.status}`)
    }
  }

  /**
   * Make a fetch() request to the game server with the required access token.
   * @param resourcePath Path of the resource to fetch.
   * @param params? Optional parameters to pass to the fetch.
   * @returns Result of the fetch.
   */
  async gameServerFetch (resourcePath: string, params: any): Promise<Response> {
    // Inject Authorization header to params
    const accessToken = await this.fetchGameServerAccessToken()
    params = {
      ...params,
      headers: { ...params.headers || {}, Authorization: `Bearer ${accessToken}` }
    }
    return await fetch(`${this.options?.gameServerUrl}${resourcePath}`, params)
  }

  /**
   * This gets patched in to the API bridge.
   * @param getWebTokenAsyncRequest Request object.
   * @returns Response wrapped in a promise.
   */
  private async getGameTokenAsync (getWebTokenAsyncRequest: GetWebTokenAsyncRequest): Promise<GetWebTokenAsyncResponse> {
    console.log("getting game token")
    const request = `${this.options?.accountApiUrl}/gameauthWebGameToken?project=${this.options?.projectName}&env=${this.options?.environment}&device=${getWebTokenAsyncRequest.deviceId}`
    const response = await fetch(request, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      cache: 'default'
    })
    const content = await response.text()
    return {
      status: response.status,
      content,
    }
  }

  /**
   * Initialize the API bridge between this MetaplayApp and a Unity instance.
   * @param unityInstance Instance to connect to.
   */
  async initApiBridge (unityInstance: UnityInstance) {
    // Get hold of the bridge and perform some sanity checks on it.
    const metaplayApiBridge = unityInstance.Module.MetaplayApiBridge
    if (!metaplayApiBridge) {
      throw Error('Unity instance missing Metaplay JS module!')
    }
    if (metaplayApiBridge.MetaplayApp) {
      throw Error('Duplicate initialization')
    }
    if (metaplayApiBridge.IsInitialized) {
      throw Error('MetaplaySDK already initialized!')
    }
    if (this.metaplayApiBridge) {
      throw Error('MetaplaySDK already connected!')
    }

    // Populate configuration object.
    // \todo: figure out the contents of this.
    metaplayApiBridge.MetaplayApp = {
      args: this.options,
      OnInitialized: undefined
    }

    // Override CompanyAccount bridge methods with our implementation.
    metaplayApiBridge.CompanyAccountWebGLApiBridge.GetWebTokenAsync = async (getWebTokenAsyncRequest: GetWebTokenAsyncRequest) => await this.getGameTokenAsync(getWebTokenAsyncRequest)
    metaplayApiBridge.WebGLBridge.OpenPurchasePopup = (url: string) => this.openPurchasePopup(url);
    metaplayApiBridge.WebGLBridge.OnXsollaPurchase = (usdRevenue: number) => this.onXsollaPurchase(usdRevenue);
    metaplayApiBridge.WebGLBridge.OnValidCachePaths = (paths: Array<string>) => this.onValidCachePaths(paths);
    metaplayApiBridge.CompanyAccountWebGLApiBridge.OpenLoginPage = (url: string) => this.loginWithPopup();
    
    // Wait for the api bridge to become initialized.
    await new Promise((resolve, reject) => { metaplayApiBridge.MetaplayApp.OnInitialized = resolve })
    if (!metaplayApiBridge.IsInitialized) {
      throw Error('MetaplaySDK failed to initialized!')
    }

    // Bridge is now valid.
    this.metaplayApiBridge = metaplayApiBridge
  }

  /**
   * Preform a login via a redirect to the login page.
   */
  loginWithRedirect () {
    const url = `${this.options?.accountApiUrl}/ingameLogin?return_to=${window.location.href}`
    window.location.href = url
  }

  /**
   * Perform a login via a popup window.
   * @param options
   */
  async loginWithPopup(options?: any) {
    singularSdk.event("loginStart");
    options = options || {}
    const width = options.width || 600
    const height = options.height || 600
    // eslint-disable-next-line no-restricted-globals
    const left = (screen.width - width) / 2
    // eslint-disable-next-line no-restricted-globals
    const top = (screen.height - height) / 2
    const popupLoginUrl = `${this.options?.accountApiUrl}/ingameLogin?return_to=${this.options?.accountApiUrl}/popupFlowCompleted`
    this.loginPopup = null
    this.loginPopup = window.open(popupLoginUrl, 'loginPopupWindow', `modal=yes, left=${left}, top=${top}, width=${width}, height=${height}, toolbar=no, resizable=no`)

    if (this.loginPopup) {
      console.log("popup not null")
      this.loginPopup.focus()
      this.loginPopup.postMessage('Metaplay:Hello', '*')
    }
    else {
      const self = this;
      const timer = setInterval(async function () {
        const newAccountInfo = await self.fetchCompanyAccountProfile();
        if (newAccountInfo.accountId) {
          clearInterval(timer);
          await self.refreshAccount();
        }
      }, 5000);
      setTimeout(function () {
        if (timer) {
          clearInterval(timer);
        }
      }, 25000);
    }

  }

  /**
   * Perform a logout via a redirect.
   */
  logoutWithRedirect () {
    const logoutUrl = `${this.options?.accountApiUrl}/logout?return_to=${window.location.href}`
    window.location.href = logoutUrl
  }

  /**
   * Fetch account info from the account server.
   */
  private async fetchCompanyAccountProfile (): Promise<AccountInfo> {
    const response = await fetch(`${this.options?.accountApiUrl}/accountInfo`, {
      method: 'GET',
      mode: 'cors',
      credentials: 'include',
      cache: 'default'
    })

    const profile = await response.json()
    return profile
  }

  /**
   * Refresh account info.
   */
  private async refreshAccount () {
    // Fetch current company accounts profile.
    const newAccountInfo = await this.fetchCompanyAccountProfile()

    if (newAccountInfo.accountId) {
      // CUSTOM
      notifyAccountChanged(newAccountInfo.accountId);
    }
    
    if (newAccountInfo.accountId !== this.accountInfo.accountId) {
      // Save new info.
      this.accountInfo = newAccountInfo
      
      // If logged in to company accounts, pre-fetch a token to access the associated game server to see that it works.
      if (this.isLoggedIn()) {
        await this.fetchGameServerAccessToken()
      }

      // Inform the game client.
      if (this.metaplayApiBridge) {
        // this.metaplayApiBridge.CompanyAccountWebGLApiBridge.AccountChanged(this.accountInfo.accountId) CUSTOM HANDLING
      }

      // Inform subscribed clients of active account.
      this.doOnAccountChanged(this.accountInfo)
    }
  }
  
  private openPurchasePopup(url : string) {
    const width = 600;
    const height = 600;
    // eslint-disable-next-line no-restricted-globals
    const left = (screen.width - width) / 2;
    // eslint-disable-next-line no-restricted-globals
    const top = (screen.height - height) / 2;
    this.purchasePopup = null
    this.purchasePopup = window.open(url, 'purchasePopupWindow', `modal=yes, left=${left}, top=${top}, width=${width}, height=${height}, toolbar=no, resizable=no`)
    if (this.purchasePopup === null)
    {
      return;
    }
    this.purchasePopup.focus();
    let purchasePopup = this.purchasePopup;
    const timer = setInterval(function() {
      if(purchasePopup.closed) {
        clearInterval(timer);
        notifyPurchasePopupClosed();
        purchasePopup = null;
      }
    }, 2000);
  }

  private onXsollaPurchase(usdRevenue : number) {
    singularSdk.revenue("Item_Purchased", "USD", usdRevenue);
  }
  
  private onValidCachePaths(paths : Array<string>) {
    for (const path of paths) {
      console.log("path ", path);
    }

    if (paths.length === 0) {
      return;
    }
    
    const dbOpen = indexedDB.open("/idbfs")
    dbOpen.onsuccess = (event) => {
      let target: any = event.target;
      const db = target.result;
      const transaction = db.transaction('FILE_DATA', 'readwrite')
      const store = transaction.objectStore('FILE_DATA')
      const cursorRequest = store.openCursor();

      cursorRequest.onerror = (error) => {
        console.log(error);
      };

      cursorRequest.onsuccess = evt => {
        const cursor = evt.target.result;
        if (cursor) {
          console.log(cursor.key);
          if (cursor.key.includes("UnityCache/Shared/") && !cursor.key.includes("UnityCache/Shared/__info")) {
            let found = false;
            for (const path of paths) {
              if (cursor.key.includes(path)) {
                found = true;
              }
            }
            if (!found) {
              console.log("Deleting ", cursor.key)
              store.delete(cursor.key);
            }
          }
          cursor.continue();
        }
      };
    };
  }
}
/**
 * Singleton instance.
 */
const metaplayApp: MetaplayApp = new MetaplayApp()

/**
 * Global getter function of MetaplayApp singleton.
 * @returns Singleton instance.
 */
export function getMetaplayApp (): MetaplayApp {
  return metaplayApp
}
