import React, { Fragment, useEffect, useState } from "react";
import {useUserInfo, useGame} from "../contextFactory";
import Welcome from "../components/Welcome";
import Community from "../components/Community";
import Download from "../components/Download";
import PlayNow from "../components/PlayNow";
import GameFrame from "../components/GameFrame";
import BuyGems from "../components/Gems/BuyGems";
import GemPacks from "../components/Gems/GemPacks";
import { useNavigate } from "react-router-dom";
import styles from "../components/PlayNow.module.scss";

const Frontpage = ({ gameOpen, gemsScroll, communityScroll }) => {
  const navigate = useNavigate();
  const [showWelcome, setShowWelcome] = useState(false);
  const { authStatus } = useUserInfo();
  const isLoggedIn = authStatus?.isLoggedIn;
  const { openGame, gameStatus } = useGame();

  useEffect(() => {
    if (gameOpen) {
      navigate("/");
      openGame();
    }
    if (gameStatus.isOpen) {
      setShowWelcome(false);
    }
    if (gemsScroll) {
      navigate("/");
      const gameScr = document.getElementById("gemContainer");
      if (gameScr) {
        gameScr.scrollIntoView();
      }
    }
    if (communityScroll) {
      navigate("/");
      const gameScr = document.getElementById("communityContainer");
      if (gameScr) {
        gameScr.scrollIntoView();
      }
    }
  }, [gameOpen, navigate, gameStatus.isOpen, openGame, gemsScroll, communityScroll]);

  useEffect(() => {
    if (isLoggedIn) {
      setShowWelcome(true);
      setTimeout(() => {
        setShowWelcome(false);
      }, 5000);
    }
  }, [isLoggedIn]);

  return (
    <Fragment>
      {showWelcome && <Welcome />}
      {gameStatus.isOpen && <div className={styles.divider}/>}
      <GameFrame />
      {!gameStatus.isOpen && <PlayNow />}
      <Download />
      <BuyGems />
      <GemPacks />
      <Community />
    </Fragment>
  );
};

export default Frontpage;
