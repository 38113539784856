import React from "react";
import { useTranslation } from "react-i18next";
import { BrowserView, isMobile } from "react-device-detect";
import { useGame } from "../contextFactory";
import { BasicButton, MobileStoreButtons } from "../sharedComponents/Buttons";
import { MainCard, TextElement } from "../sharedComponents/CardComponents";
import Picture from "./Picture";
import styles from "./PlayNow.module.scss";

const PlayNow = () => {
  const { t } = useTranslation();
  const { openGame } = useGame();

  return (
    <MainCard cardStyle={["rowCard", "playNow"]}>
      <TopImageContent />
      <div className={styles.logoOverImage}>
        <Picture
          altText={t("Common.FadLogo")}
          picStyle="logoImage"
          source="images/fadLogo.png"
        />
      </div>
      <div className={styles.contentOverImage}>
        <BrowserView>
          <BasicButton
            buttonStyle={["success", "significant", "hideInMobile"]}
            label={t("Pages.FrontPage.PlayNow.ButtonText")}
            onClick={openGame}
          />
        </BrowserView>
        <OverImageContent t={t}/>
        <MobileStoreButtons />
      </div>
    </MainCard>
  );
};

export const OverImageContent = ({ t }) => {
  if (isMobile) {
    return (
      <TextElement customStyle="overTopImageMobile">
        {t("Pages.FrontPage.Download.DownloadGameMobile")}
      </TextElement>
    )
  } else {
    return (
      <TextElement customStyle="overTopImage">
        {t("Pages.FrontPage.Download.DownloadGame")}
      </TextElement>
    )
  }
};

export const TopImageContent = () => {
  if (isMobile) {
    return (
      <Picture
        source="/images/imageTopNarrow.png"
        altText="Common.FadLogo"
        picStyle="topImage"
      />
    )
  } else {
    return (
      <Picture
        source="/images/imageTop.png"
        altText="Common.FadLogo"
        picStyle="topImage"
      />
    )
  }
};
  
export default PlayNow;
