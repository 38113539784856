import React, {useCallback, useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {
  CardHeader,
  MainCard,
  ProductCard,
  TextContainer
} from "../../sharedComponents/CardComponents";
import Picture from "../Picture";
import {
  gameTokenConfig,
  getCurrencySymbol,
  getPaymentTokenConfig,
  getXsollaTokenConfig,
  xsollaProductsConfig,
} from "../../utils/shopUtils";
import {useError, useLoading, useUserInfo} from "../../contextFactory";
import styles from "./Gems.module.scss";
import useHttp from "../../hooks/useHttp";
import {Divider} from "../Icons";
import { getMetaplayApp } from '../../MetaplayApp.ts'

const Gem = ({ product }) => {
  const { setError } = useError();
  const { sendRequest } = useHttp();
  const { isLoading } = useLoading();
  const { authStatus, setProductFetchPending } = useUserInfo();
  const itemSku = product.sku;
  const currency = getCurrencySymbol(product.price?.currency);
  const isDiscounted =
    product.price?.amount !== product.price?.amount_without_discount;
  let purchasePopup;

  const handleGemClick = () => {
    if (isLoading) return;

    const isLoggedIn = authStatus?.isLoggedIn;
    if (!isLoggedIn) {
      getMetaplayApp().loginWithPopup();
      return;
    }
    
    const width = 600;
    const height = 600;
    // eslint-disable-next-line no-restricted-globals
    const left = (screen.width - width) / 2;
    // eslint-disable-next-line no-restricted-globals
    const top = (screen.height - height) / 2;
    purchasePopup = window.open("", 'purchasePopupWindow', `modal=yes, left=${left}, top=${top}, width=${width}, height=${height}, toolbar=no, resizable=no`)

    handlePurchaseStart();
  };

  const handlePurchaseStart = async () => {
    try {
      const gameToken = await sendRequest(gameTokenConfig);
      const paymentTokenConfig = getPaymentTokenConfig(gameToken, itemSku);
      const paymentToken = await sendRequest(paymentTokenConfig);
      const xsollaTokenConfig = getXsollaTokenConfig(paymentToken, itemSku);
      const xsollaToken = await sendRequest(xsollaTokenConfig);

      if (!xsollaToken) return;

      const products = await sendRequest(xsollaProductsConfig(gameToken));
      let found = false;
      for (const item of products.items) {
        if (item.sku === itemSku) {
          found = true;
          break;
        }
      }

      if (!found) {
        setProductFetchPending(Date.now())
        return;
      }

      const gemUrl = `${
        process.env.REACT_APP_XSOLLA_PAYSTATION_URL
      }/?${new URLSearchParams({
        access_token: xsollaToken,
      })}`;
      openPurchasePopup(gemUrl);
    } catch (error) {
      setError(error);
    }
  }
  
  const hardCodedInfos = {
    "gems_501": "575 Gems \n Bonus 150 Gems",
    "gems_1201": "1350 Gems \n Bonus 400 Gems",
    "gems_2501": "2800 Gems \n Bonus 750 Gems",
    "gems_6501": "7250 Gems \n Bonus 2000 Gems",
    "gems_14001": "15500 Gems \n Bonus 4500 Gems",
    "gems_500": "575 Gems",
    "gems_1200": "1350 Gems",
    "gems_2500": "2800 Gems",
    "gems_6500": "7250 Gems",
    "gems_14000": "15500 Gems",
    "gems_37500": "33750 Gems",
    "webcvrstarter_15": "5-Star Scroll \n 2100 Gems \n 25 Talent Runes \n "
  }

  
  const openPurchasePopup = (url) => {
    if (purchasePopup != null) {
      purchasePopup.location = url;
    }
    else {
      const width = 600;
      const height = 600;
      // eslint-disable-next-line no-restricted-globals
      const left = (screen.width - width) / 2;
      // eslint-disable-next-line no-restricted-globals
      const top = (screen.height - height) / 2;
      purchasePopup = window.open(url, 'purchasePopupWindow', `modal=yes, left=${left}, top=${top}, width=${width}, height=${height}, toolbar=no, resizable=no`)  
    }
    
    if (purchasePopup != null) {
      purchasePopup.focus();
      const timer = setInterval(function() {
        if(purchasePopup?.closed) {
          clearInterval(timer);
          console.log("Purchase popup closed");
          setProductFetchPending(Date.now());
          purchasePopup = null;
        }
      }, 2000);
    }
  }

  const formatName = (name) => {
    if (!name) {
      return name
    }
    
    const indexOfPlus = name.indexOf('+')
    if (indexOfPlus === -1) {
      return name
    }
    return [name.slice(0, indexOfPlus), "\n", name.slice(indexOfPlus)].join('');
  }
  
  return (
    <ProductCard
      currency={currency}
      discountPrice={isDiscounted && product.price.amount}
      name={formatName(product?.name)}
      onClick={handleGemClick}
      picture={
        <Picture
          source={product?.image_url || "/images/gemPack.png"}
          picStyle="gemImage"
        />
      }
      price={product?.price?.amount_without_discount}
      info={hardCodedInfos[product.sku]}
    />
  );
};

const GemContainer = () => {
  const { t } = useTranslation();
  const [products, setProducts] = useState([]);
  const { sendRequest } = useHttp();
  const { authStatus, productFetchPending } = useUserInfo();
  const isLoggedIn = authStatus?.isLoggedIn;
  const lastProductFetch = useRef({
      time: 0,
      isLoggedIn: false
  });

  const productFetchThrottleMs = 5000;

  const refreshProducts = useCallback(async (useLoading) => {
    if (lastProductFetch.current.isLoggedIn === isLoggedIn && Date.now() <= lastProductFetch.current.time + productFetchThrottleMs) {
      console.log('Already up to date ');
      return;
    }
    lastProductFetch.current = {
      time: Date.now(),
      isLoggedIn: isLoggedIn
    };
    
    console.log('Updating products');
    let gameToken = '';
    if (authStatus.isLoggedIn) {
      gameToken = await sendRequest(gameTokenConfig, null, useLoading);
    }
    await sendRequest(xsollaProductsConfig(gameToken), setProducts, useLoading);
  }, [isLoggedIn, sendRequest, authStatus.isLoggedIn]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      refreshProducts(false);
    }, 60000);
    return () => clearInterval(interval);
  }, [refreshProducts]);

  useEffect(() => {
    refreshProducts(true);
  }, [isLoggedIn, refreshProducts, productFetchPending]);

  return (
    <div className={styles.gemContainer}>
      {products?.items?.length > 0 ? (
        sortedProducts(products).map((product) => (
          <Gem product={product} key={product.item_id} />
        ))
      ) : (
        <CardHeader
          subHeader={true}
          title={t("Pages.FrontPage.Gems.NoGems")}
        />
      )}
    </div>
  );
};

function sortedProducts(products) {
  products.items.sort(compareProducts);
  return products.items;
}

function compareProducts(a, b) {
  let offerExtra = 0
  if (!a.sku.includes("gems")) {
    offerExtra = 9999999
  }
  if (!b.sku.includes("gems")) {
    offerExtra = -9999999
  }
  return a.price.amount - b.price.amount - offerExtra;
}

const GemPacks = () => {
  const { t } = useTranslation();

  return (
    <MainCard cardStyle={["gemContainer", "columnCard"]} id="gemContainer">
      <TextContainer>
        <CardHeader title={t("Pages.FrontPage.Gems.Packs")} />
        <Divider />
        <CardHeader customStyle="gemListHeader" subHeader={true} title={t("Pages.FrontPage.Gems.Get")} />
      </TextContainer>
      <GemContainer />
    </MainCard>
  );
};

export default GemPacks;
