import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { NewsContextLayout } from "../contextFactory";
import { useUserInfo } from "../contextFactory";
import ErrorComponent from "../components/Error/ErrorComponent";
import NewsItem from "../containers/News/NewsItem";
import Frontpage from "../containers/Frontpage";
import NewsList from "../containers/News/NewsList";

const AdminTool = React.lazy(() => import("../containers/Admin/AdminTool"));
const AdminCorner = React.lazy(() => import("../containers/Admin/AdminCorner"));
const hashedAdminCornerPath = process.env.REACT_APP_ADMINCORNER_PATH;

const AdminToolElement = (
  <React.Suspense
    fallback={<ErrorComponent returnTo={-1} textId="Errors.AdminTool" />}
  >
    <AdminTool />
  </React.Suspense>
);

const AdminCornerElement = (
  <React.Suspense
    fallback={
      <ErrorComponent returnTo={-1} textId="Errors.AdminCorner.NotFound" />
    }
  >
    <AdminCorner />
  </React.Suspense>
);

const ProtectedRoute = ({ isAdmin, children, navigateTo }) => {
  if (!isAdmin) {
    return <Navigate to={navigateTo} replace />;
  }
  return children;
};

const AppRoutes = () => {
  const { authStatus } = useUserInfo();
  const isAdmin = authStatus?.isAdmin;

  return (
    <Routes>
      <Route path="/" element={<Frontpage />} />
      <Route path="/game" element={<Frontpage gameOpen={true} />} />
      <Route path="/gems" element={<Frontpage gemsScroll={true} />} />
      <Route path="/community" element={<Frontpage communityScroll={true} />} />
      <Route path={hashedAdminCornerPath} element={AdminCornerElement} />
      <Route element={<NewsContextLayout />}>
        <Route path="/news" element={<NewsList />} />
        <Route path="/news/:id" element={<NewsItem />} />
        <Route
          path="/admintool"
          element={
            <ProtectedRoute isAdmin={isAdmin} navigateTo={"/"}>
              {AdminToolElement}
            </ProtectedRoute>
          }
        />
        <Route
          path="/admintool/:id"
          element={
            <ProtectedRoute isAdmin={isAdmin} navigateTo={"/"}>
              {AdminToolElement}
            </ProtectedRoute>
          }
        />
      </Route>
      <Route
        path="/news/notfound"
        element={
          <ErrorComponent returnTo={"/news"} textId="Errors.NoNewsItem" />
        }
      />
      <Route
        path="/admintool/notfound"
        element={
          <ErrorComponent returnTo={"/admintool"} textId="Errors.NoNewsItem" />
        }
      />
      <Route
        path="*"
        element={<ErrorComponent returnTo={-1} textId="Errors.PageNotFound" />}
      />
    </Routes>
  );
};

export default AppRoutes;
